export const removeHellobarUrl = [
    "/global-corporate-virtual-walkathon/",
    "/registration-thank-you-global-corporate-virtual-walkathon/",
    "/leaderboard/",
    "/contest-guidelines-global-corporate-virtual-walkathon/",
    "/corporate-wellness-with-vantage-fit/",
    "/vantage-fit-webcast-registration/",
    "/fr/",
    "/fr/wellness-challenges/",
    "/fr/wellness-programs/",
    "/fr/rewards-and-recognition/",
    "/fr/wellbeing-community/",
    "/fr/planifier-demo/",
];

export const hellobarTextForUrl = [
    {
        slug: [""],
        buttonText: "Get It Now",
        helloBarBg: "rgba(255, 76, 76, 0.92)",
        color: "white",
        buttonBg: "#fff",
        buttonColor: "#f15162",
    },
];

export const gdprCountries = [
    "Austria",
    "Belgium",
    "Bulgaria",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Germany",
    "Greece",
    "Hungary",
    "Ireland",
    "Italy",
    "Latvia",
    "Lithuania",
    "Luxembourg",
    "Malta",
    "Netherlands",
    "Poland",
    "Portugal",
    "Romania",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
    "United Kingdom",
];
