import React, { createContext, useState, useEffect } from "react";

export const isMobileContext = createContext();

export const IsMobileProvider = ({ children }) => {
    const [isMobile, setIsMobile] = useState(() => {
        // Check if window exists (only in the browser) and use innerWidth, otherwise default to false
        return typeof window !== "undefined" ? window.innerWidth <= 768 : false;
    });

    useEffect(() => {
        if (typeof window !== "undefined") {
            const handleResize = () => {
                setIsMobile(window.innerWidth <= 768);
            };

            window.addEventListener("resize", handleResize);

            return () => {
                window.removeEventListener("resize", handleResize);
            };
        }
    }, []);

    return (
        <isMobileContext.Provider value={isMobile}>
            {children}
        </isMobileContext.Provider>
    );
};
